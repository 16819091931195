.slider {
  max-height: 50vh;
  position: relative;
  overflow: clip;

}

/* General styles for the slider */
.slider-container {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  overflow: clip;
  max-width: 100vw;
}

.slider-container img {
  display: inline-flex;
  flex-direction: row;
  /* max-width: 100%; */
  max-height: 50vh;
  transition: opacity 0.5s ease;
  aspect-ratio: 16 / 9;
}

.slider-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 10%; /* Adjust as needed */
  text-align: center;
}

.slider-container:hover img {
  opacity: 0.2;
}

.slider-container:hover .overlay {
  opacity: 1;
}

/* Styles for modal */
.slidermodal {
  background-color: white;
  padding: 5%;
  border-radius: 10px; /* Adjust the value for desired roundness */
  width: 60%; /* Adjusted to take up 60% of the screen width */
  max-width: 800px; /* Optional: set a maximum width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
  height: 50vh;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center; /* Optional: center the text inside the modal */
}



/* Media queries for smaller screens */
@media (max-width: 768px) {
  .slider {
    aspect-ratio: 16 / 9;

  }

.slider-container img {
  /* width: auto;
  height: 50vh; */
}

.slider-container .overlay {
padding: 10%; /* Reduce padding for smaller screens */
font-size: 0.8em;
/* height: 100%; */
}

.slidermodal {
padding: 10%; 
/* height: 100%; /* Increase width for better visibility on smaller screens /
max-width: 600px; / Optional: adjust maximum width */
overflow: clip;
}
}
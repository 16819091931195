/*--------------------
Footer Styles 
----------------------*/

.footer-wrapper{
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items:center;
}

.footer-left {
    flex-direction: row;
    justify-content: flex-start;

    @media #{$lg-layout} {
        margin-left: 50px;
    }

    @media #{$md-layout} {
        margin-left: 20px;
    }

    @media #{$sm-layout} {
        margin-left: 0px;
    }
}

.footer-right {
    right: 0;
    flex-direction: row;
    justify-content: flex-end;
    .footer-link {
        ul {
            &.ft-link {
                li {
                    display: inline-block;
                    padding: 0 20px;
                    color: #adb5bd;
                    font-size: 16px;
                    font-weight: 500;
               
                    &:hover {
                        background: #fff;
                        border-color: #fff;
                        color: $theme-color;
                    }
                }
            }
        }
    }

    
    @media #{$lg-layout} {
        margin-right: 50px;
    }

    @media #{$md-layout} {
        margin-right: 20px;
    }

    @media #{$sm-layout} {
        margin-right: 0px;
    }

}
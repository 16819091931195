/* Tab Style  */

.tabs-area{
    background-color:#E3F2F3;
    padding-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;

    @media #{$md-layout} {
        padding-left: 30px;
        padding-right: 30px;
    
    }

    @media #{$sm-layout} {
        padding-left: 10px;
        padding-right: 10px;
    
    }

}


ul{
    &.tab-style--1 {
        @extend %liststyle;
        display: flex;
        margin: 20px 0px; // <--------------------------------------------------------------------------this changes the experience position, but related to the whole block
        flex-wrap: wrap;

        li {
            position: relative;
            margin: 0 20px;
            display: inline-block;
            padding-bottom: 4px;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            outline: none;
            
            @media #{$lg-layout} {
                margin: 0 10px;
            }

            @media #{$md-layout} {
                margin: 0 10px;
                font-size: 16px;
            }

            @media #{$sm-layout} {
                margin-bottom: 10px;
                font-size: 14px;
            }

            &::before {
                position: absolute;
                content: "";
                width: 30px;
                background: rgba(0, 0, 0, 0.2);
                transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
                height: 2px;
                bottom: 0;

            }
            &.react-tabs__tab--selected{
                color: $theme-color;
                &::before{
                    width: 100%;
                    background: currentColor;
                }
            }
        }
    }
    
}

.single-tab-content {
    padding-top: 0;

    h1 {
        text-align: center;
        font-size: 18px;
        color: #000;
        line-height: 1.8;
        margin: 0px 50px;

        @media #{$md-layout} {
            margin: 0px 50px;
            font-size: 15px;
        }
        
        @media #{$sm-layout} {
            font-size: 13px;
            margin: 0px 20px;
            //line-height: 1.3;
        }
    }

    p {
        text-align: left;
        font-weight: 300;
        color: #000;
        font-size: 16px;
        line-height: 1.8;
        margin: 10px 50px;

        
        @media #{$md-layout} {
            font-size: 13px;
            margin: 10px 50px;
        }
        
        @media #{$sm-layout} {
            font-size: 11px;
            margin: 10px 20px;
            //line-height: 1.3;
            text-align: justify;
        }
    }
}



.single-tab-content{
    ul{
        padding: 0;
        //margin: 0 -200px;
        list-style: none;
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 0 auto; //this is the key bit!


        @media #{$md-layout} {
        }
        

        @media #{$sm-layout} {
            display: flex;
            flex-flow: column;
            align-items: center;
            //margin: 0 auto; //this is the key bit!
        }

        
        li{
            color: #7e7e7e;
            font-size: 16px;
            line-height: inherit;
            margin: 10px 20px;
            font-weight: 300;
            background-color:#fff;
            padding-top: 30px;
            padding-bottom: 30px;
            border-radius: 10px;
            a{
                font-weight: 500;
                display: block;
                color: #717173;
                span{
                    font-weight: 300;
                }

                @media #{$sm-layout} {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}













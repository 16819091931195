.Header-Hero {
    //height: 650px;
    //width: 100vw;
    position: relative;
    //background-size: cover;
    //background-position: 50% 50%;
    //top: "75%";
    //left: "75%";
    //transform: "translate(-50%,-50%)";
};

.Header-Hero-internal {
    position: absolute;
    bottom: 0%;
    padding-top: 100px;
    padding-bottom: 30px;
    padding-right: 20vw;
    width: 100%;
    background:#00000088;
    //opacity: 0.5;
    transition: 0.3s;
    color: $border-color;
    text-align: right;
    white-space: pre-wrap;
    line-height: 1.1;

    @media #{$md-layout} {
        padding-top: 80px;
        padding-bottom: 30px;
        padding-right: 10vw;
    }
    
      @media #{$sm-layout} {
        padding-top: 50px;
        padding-bottom: 30px;
        padding-right: 10vw;
    }

    h1 {
        text-align: right;
        color: #FFFFFF;//$border-color;
        font-size: 60px;

        @media #{$md-layout} {
            font-size: 40px;
        }
        
          @media #{$sm-layout} {
            font-size: 28px;
        }
      }

    h2 {
        padding-top: 10px;
        text-align: right;
        color: #FFFFFF;
        font-size: 36px;
        /* font-weight: bold; */

        @media #{$md-layout} {
            font-size: 30px;
        }
        
          @media #{$sm-layout} {
            font-size: 18px;
        }
      }


}

.bio-container {
  position: relative;
  z-index:-1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-color:#E3F2F3;
}

.bio-container-inner {
  position:relative;
  z-index:1;
  height: 100%;
  width: 100%;
  margin-top: 7.5%;
  margin-bottom: 7.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color:#E3F2F3;
  line-height: 1.8;
}

.bio-container-inner > h1 {
  color: #2e323cad;
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: 10px;
  font-size: 36px;
  align-items: center;
  justify-content: center;
  //font-family: Arial, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', sans-serif;

  @media #{$md-layout} {
    font-size: 30px;
    margin-left: 15%;
    margin-right: 15%;
}

  @media #{$sm-layout} {
    font-size: 22px;
    margin-left: 7.5%;
    margin-right: 7.5%;
}
}

.bio-container-inner > p {
  color: #2e323cad;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 20px;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  //font-family: Arial, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', sans-serif;

  @media #{$md-layout} {
    font-size: 13px;
    margin-left: 15%;
    margin-right: 15%;
}

@media #{$sm-layout} {
    font-size: 11px;
    margin-left: 7.5%;
    margin-right: 7.5%;
}
}